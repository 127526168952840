.html {
    font-size: 10px;
    min-width: 360px;
    background: #f5f5f5;
}

.body {
    background: #f5f5f5;
}

.html, .body {
    width: 100%;
    height: auto;
}

.mainApp {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    display: flex;
    max-width: 100%;
    min-width: 360px;
    min-height: 100vh;
    flex-direction: column;
}